@import "../../styles/scss/break_point.scss";

.auth {
  position: relative;
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $adm-background-burger;
  .wrapper {
    width: 50rem;
    .column_form {
      position: relative;
      padding: 3rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      @include style-conteiner;
      .enter_title {
        text-align: center;
        font-size: 3rem;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}
