@import "../../../../styles/scss/break_point.scss";

.user_wrapper {
  width: 100%;
  max-width: 100rem;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding: 3rem 2rem;
  gap: 2rem;
  @include style-conteiner;
  .title {
    font-size: $admin-l;
    font-weight: 500;
  }
  .form_employee {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 2rem;
    .wrapper_input {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 100%;
    }
    .form_button {
      margin-top: auto;
    }
  }
}
