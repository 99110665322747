@import '../../../styles/scss/break_point.scss';

.body {
	position: absolute;
	max-width: 50rem;
	min-width: 28rem;
	height: auto;
	min-height: 30rem;
	max-height: 80%;
	@include style-conteiner;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	.header {
		display: flex;
		align-items: center;
		gap: 1rem;
		.botton_exit {
			background-color: $adm-white;
			svg {
				font-size: $admin-xl;
				transition: all 0.5s ease-out;
				&:active {
					transform: rotate(180deg);
				}
			}
		}
		span {
			font-size: $admin-m;
			font-weight: 500;
		}
	}
	.crop {
		width: 100%;
		overflow: hidden;
	}
	.footer {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		.accept {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: -3.5rem;
			background-color: $adm-white;
			font-size: $admin-xxxl;
			z-index: 5;
			border: 2px solid $adm-black;
			border-radius: 50%;
			width: 1.5em;
			height: 1.5em;
			transition: all 0.3s ease;
			&:hover {
				background-color: $bg-color;
				border-color: $border-color;
				color: $adm-white;
			}
		}
	}
}
