@import "../../../styles/scss/break_point.scss";

.table {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  @include style-conteiner;
  overflow: hidden;
  .row {
    display: grid;
    grid-template-columns: 0.7fr 0.7fr 1.3fr 0.6fr 0.8fr 0.6fr;
    column-gap: 1fr;
    width: 100%;
    height: 5rem;
    align-items: center;
    font-size: $admin-m;
    padding: 0 1rem;
    transition: background-color 0.3s ease;

    &:not(:last-child) {
      border-bottom: 1px solid $adm-border;
    }
    &:hover {
      background-color: rgb(236, 236, 236);
    }
    &.header {
      background-color: $adm-background-burger;
      color: $adm-white;
      font-weight: 600;
      font-size: $admin-l;
    }
  }
}
