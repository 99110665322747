@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './break_point.scss';
html {
	font-size: 62.5%;
	line-height: 1.25;
	// scrollbar-gutter: stable;
}
body {
	height: 100%;
	font-family: 'Montserrat', sans-serif;
	width: 100%;
	overflow: auto;
}

._cont_limit {
	max-width: 43rem;
	padding: 0rem 1.5rem;
	margin: 0rem auto;
	height: 100%;
	box-sizing: content-box;
}

._after-cont-limit-flex {
	position: relative;
	width: 100%;
	height: 100dvh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.main {
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	position: relative;
	color: var(--body-color);
}
.border_and_bg {
	background-color: $bg-color;
	border: 1px solid $border-color;
	box-shadow: $shadow-small;
	border-radius: 1rem;
}

.bg {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	z-index: -2;
	img {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		object-fit: fill;
	}
}
.slide-out {
	position: fixed;
	width: 100%;
	height: 100dvh;
	top: 0;
	left: 0;
	background-color: $bg-color;
	transform-origin: bottom;
	z-index: 10000;
}
.title {
	font-size: $xl;
	font-weight: 700;
}
.sub_title {
	padding: 1rem;
	font-size: $s;
	line-height: 1.4;
	font-weight: 500;
}
