@import '../../../styles/scss/break_point.scss';

.body {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 2rem;
	width: 30%;
	min-width: 27.5rem;
	@include style-conteiner;
	gap: 2rem;
	align-items: center;
	font-size: $admin-xl;
	font-weight: 500;
	.flex_button {
		display: flex;
		flex-wrap: nowrap;
		gap: 1rem;
		button {
			@include button-white;
			font-size: $admin-l;
		}
	}
}
