@import "../../styles/scss/break_point.scss";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  .logo {
    margin-bottom: 2rem;
    img {
      width: 15rem;
    }
  }
  .subtitle {
    align-self: flex-start;
    font-size: $s;
    margin: 1.2em 0em 0.5em 0em;
    font-weight: 600;
  }
  .navigate_platforms {
    position: relative;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
    width: 100%;
    button {
      position: relative;
      height: 7rem;
      width: 100%;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 4rem;
      img {
        width: 4.5rem;
      }
    }
  }
}
