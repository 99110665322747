@import '../../../../styles/scss/break_point.scss';

.wrapper {
	position: relative;
	height: 100%;
	min-width: 37rem;
	.body {
		position: sticky;
		@include style-conteiner;
		display: flex;
		flex-direction: column;
		padding: 1em;
		font-size: $admin-m;
		gap: 1.2em;

		.delete {
			position: absolute;
			z-index: 2;
			top: 1em;
			right: 1em;
			font-size: $admin-s;
			svg {
				vertical-align: top;
				font-size: $admin-m;
			}
		}
		.avatar {
			display: flex;
			align-items: center;
			justify-content: center;

			.circle {
				border: 1px solid $adm-black;
				border-radius: 50%;
				width: 10rem;
				height: 10rem;
				overflow: hidden;
				img {
					object-fit: fill;
					width: inherit;
					height: inherit;
				}
			}
		}
		.wrapper_block {
			display: flex;
			flex-direction: column;
			gap: 0.8em;
			.item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0.5em;
				border: 1px solid $adm-border;
				background-color: $adm-background;
				border-radius: 1rem;
				transition: all 0.3s ease;
				&:hover {
					box-shadow: $shadow-small;
					.icon {
						opacity: 0.9;
						scale: 1.1;
					}
				}
				.text {
					display: flex;
					flex-direction: column;
					.title {
						font-size: $admin-s;
						margin-bottom: 0.4em;
					}
					.num {
						font-weight: 500;
						margin-left: 0.2em;
					}
				}
				.icon {
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0.6;
					transition: inherit;
					svg {
						height: 3.5rem;
						width: 3.5rem;
					}
				}
			}
		}
		.qr_block {
			display: flex;
			justify-content: space-between;
		}
		.info_block {
			display: flex;
			flex-direction: column;
			gap: 1em;
			width: 100%;
			.info {
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				&::before {
					content: '';
					position: absolute;
					width: 100%;
					left: 0;
					bottom: 2px;
					background: 0 100% repeat-x
						radial-gradient(
							at center center,
							#828282 1px,
							#828282 0px,
							transparent 0
						);
					background-size: 5px 4px;
					height: 4px;
					z-index: 1;
				}
				.title {
					font-weight: 500;
					background-color: $adm-white;
					z-index: 2;
				}
				.data {
					white-space: nowrap;
					background-color: $adm-white;
					z-index: 2;
				}
			}
		}
	}
}
