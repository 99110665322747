@import "../../../styles/scss/break_point.scss";

.body_worker {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: column;
  gap: 3rem;
  margin: 2rem 0;
  .header {
    align-items: flex-start;

    img {
      width: 12rem;
    }
  }

  .item_flex {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 10px 50px 50px 10px;
    height: 10rem;
    overflow: hidden;
    .speciality {
      font-size: $s;
      font-weight: 200;
    }
    .fio {
      font-size: $m;
      font-weight: 500;
    }
    .raiting {
      font-size: $xs;
      font-weight: 300;
    }
    .column:first-child {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
    }
    .column:last-child {
      position: relative;
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 8rem;
        height: 8rem;
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
  }
}
