@import "../../../styles/scss/break_point.scss";
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.8);
  }

  60% {
    transform: scale(0.9);
  }
}
@keyframes bounceSubmit {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.9);
  }

  60% {
    transform: scale(0.95);
  }
}
.item {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  .title {
    font-size: $l;
    margin-bottom: 0.5em;
    font-weight: 600;
  }

  //grade кнопки оценок

  .row {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    .active_grade {
      background-color: $red;
    }
    button {
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      // &:active {
      //   animation: bounce 0.4s ease;
      // }
      &:focus {
        animation: bounce 0.4s ease;
      }

      svg {
        height: 4rem;
        width: 4rem;
        color: white;
      }
    }
  }

  // форма

  .wrapper_input {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
    gap: 0.7rem;
    .input_text {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      gap: 0.3rem;
      &:first-child {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
        height: 12rem;
      }
      textarea,
      input {
        background-color: inherit;
        width: 100%;
        height: 100%;
        box-shadow: none;
        resize: none;
        color: inherit;
        font-size: $s;
        &::placeholder {
          font-weight: 500;
        }
      }

      .input_error {
        color: red;
        font-weight: 300;
      }
    }
  }
  .submit {
    margin-top: 1em;
    width: 100%;
    color: inherit;
    font-size: $m;
    font-weight: 600;
    &:focus {
      animation: bounceSubmit 0.3s ease;
    }
  }
}
//чекбокс
.accept {
  position: relative;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  z-index: 1;
  overflow: hidden;
  .blur_bg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(131, 114, 114, 0.11);
    z-index: -1;
    backdrop-filter: 16px;
  }
  .custom_checkbox {
    position: relative;
    display: inline-block;
    font-size: $s;
    padding-left: 3rem; /* Резервируем место под кастомный чекбокс */
    cursor: pointer;
    input {
      position: absolute;
      opacity: 0; /* Скрываем оригинальный чекбокс */
      cursor: pointer;
      &:checked + .checkmark {
        background-color: #fff; /* Когда checked - белый фон */
        .checkmark_icon {
          visibility: visible;
        }
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 1.8rem;
      width: 1.8rem;
      background-color: transparent;
      border: 1px solid #fff; /* Белый цвет бордера */
      border-radius: 50%; /* Делаем круглый */
    }
    .checkmark_icon {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: black;
      font-size: 1.3rem;
      visibility: hidden;
    }
  }
}
