@import "../../styles/scss/break_point.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  .logo {
    img {
      width: 15rem;
    }
  }
  .title {
    font-size: $xl;
    font-weight: 700;
  }
  .sub_title {
    padding: 1rem;
    font-size: $s;
    line-height: 1.4;
    font-weight: 500;
  }
}
