@import "../../styles/scss/break_point.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  color: $adm-white;
  .title {
    align-self: center;
    font-size: 10rem;
    font-weight: 800;
  }
  .sub_title {
    padding: 1rem;
    font-size: $xxl;
    line-height: 1.4;
    font-weight: 500;
  }
}
