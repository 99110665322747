@import '../../../../styles/scss/break_point.scss';

.form_place {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	margin: 2rem 0 1rem 0;
	padding: 2rem 1rem;
	@include style-conteiner;
	.title_add {
		font-size: $admin-l;
		font-weight: 500;
	}
	.wrapper_input {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 2rem;
	}
}
