@import "../../../styles/scss/break_point.scss";

header {
  position: fixed;
  width: 100%;
  height: $height-header;
  z-index: 8888;
  transition: padding-left 0.4s ease-in-out;
  padding-left: var(--burger-with);
  .conteiner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $adm-white;
    border-bottom: 1px solid $adm-border;
    padding: 0rem 2rem;
    .right_components {
      display: flex;
      align-items: center;
      gap: 2rem;
      font-size: $admin-m;
      font-weight: 500;
    }
    .exit {
      font-size: inherit;
      border: 1px solid $adm-border;
      border-radius: 10px;
      padding: 0.2em 0.4em;
      background-color: $adm-background;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: $shadow-small;
      }
    }
    .left_components {
      position: relative;
      display: flex;
      justify-content: center;
      width: 3.4rem;
      height: 2.2rem;
      cursor: pointer;
      .burger_body {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        &:hover span:nth-child(1),
        &:hover span:nth-child(3) {
          width: 85%;
        }

        &:hover span:nth-child(2) {
          width: 55%;
        }

        span {
          position: absolute;
          transition: width 0.2s ease-out;
          height: 2px;
          width: 100%;
          background-color: rgb(0, 0, 0);

          &:nth-child(1) {
            top: 0;
          }
          &:nth-child(2) {
            top: 1rem;
          }
          &:nth-child(3) {
            bottom: 0;
          }
        }
      }
    }
  }
}
