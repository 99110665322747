.change_page {
  position: relative;
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8em;
  font-size: clamp(1.4rem, calc(1.359rem + 0.135vw), 1.6rem);
  font-weight: 500;

  button {
    font-size: inherit;
    font-weight: inherit;
    background-color: white;
    height: 2.5em;
    padding: 0em 1em;
    border-radius: 25px;
  }
  .arrow_botton {
    display: flex;
    align-items: center;
    gap: 0.7em;
  }
  .list_page {
    display: flex;
    align-items: center;
    gap: 0.9em;

    button {
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
      transition: all 0.2s ease;
    }
  }
}
