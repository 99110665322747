@import "../../styles/scss/break_point.scss";

.loader {
  position: fixed;
  width: 100%;
  height: 100dvh;
  background-color: $bg-color;
  font-family: "AdihausDIN-SemiBold", sans-serif;
  overflow: hidden;
  z-index: 999;
  .conteiner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .img_block {
    img {
      width: 9rem;
    }
  }
  .logo_block {
    display: flex;
    overflow: hidden;
    flex-wrap: nowrap;
    font-size: 7rem;
    line-height: 0.65;
    letter-spacing: 0.03em;
    padding-top: 2.6rem;

    span {
      position: relative;
      display: inline-block;
      white-space: nowrap;
      text-transform: uppercase;
    }
  }
}
